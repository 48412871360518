import { useEffect, useState } from "react";
import { MdAddCircle } from "react-icons/md";
import { MdOutlineChangeCircle } from "react-icons/md";
import { TbCurrentLocation } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import { GiMonclerJacket } from "react-icons/gi";
import { GiTrousers } from "react-icons/gi";
import { GoPerson } from "react-icons/go";
import { Link } from "react-router-dom";
import axios from "axios";
const Admin = (props) => {
    const [success, setSuccess] = useState('')
    const [statusIsChanged, setStatusIsChanged] = useState(false)
    const [stateProducts, setStateProducts] = useState(props.products || [])
    const [stateUsers, setStateUsers] = useState(props.users || [])
    const [stateOrders, setStateOrders] = useState(props.orders || [])
    const [stateID, setStateID] = useState(null)
    const [isOpenSizesTable, setIsOpenSizesTable] = useState('')
    const [rowsTable, setRowsTable] = useState([])
    const users = {
        registered: stateUsers.filter(user => user.login),
    }
    const levels = {
        first_level: stateProducts.filter((product, id, arr) => id === arr.findIndex(t => t.first_level_season === product.first_level_season)).map(item => item.first_level_season),
        second_level: stateProducts.filter((product, id, arr) => id === arr.findIndex(t => t.second_level_type === product.second_level_type)).map(item => item.second_level_type)
    }
    useEffect(() => {
        setStateProducts(props.products)
        setStateUsers(props.users)
        setStateOrders(props.orders)
    }, [props.products, props.users, props.orders])
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handleProductLink = (id) => {
        localStorage.setItem('lastPosScroll', window.scrollY);
        handleScrollToTop()
    }
    const [openMenu, setOpenMenu] = useState({
        'addProduct': false,
        'changeProduct': false,
        'currentOrders': false,
        'allUsers': false,
    })
    const handleOpenMenu = (open) => {
        const newOpen = {
            ...openMenu, [open]: !openMenu[open]
        }
        setOpenMenu(newOpen)
    }
    const [addProductForm, setAddProductForm] = useState({
        name: "",
        size: "",
        cost: "",
        type: "",
        season: "",
        gender: "m",
        namePhoto: "",
        countPhoto: "",
        day: "",
        month: "",
        year: "",
        popularity: "",
        article: "",
        title: "",
        description: ""
    })
    const sendAddProductForm = (e) => {
        e.preventDefault()
        axios.post(`https://poghosty.ru/api/poghosty-adminAddProduct.php`, {
            name: addProductForm.name, size: addProductForm.size, cost: addProductForm.cost, type: addProductForm.type, season: addProductForm.season,
            gender: addProductForm.gender, namePhoto: addProductForm.namePhoto, countPhoto: addProductForm.countPhoto, date: addProductForm.year + '-' + addProductForm.month + '-' + addProductForm.day,
            popularity: addProductForm.popularity, article: addProductForm.article, title: addProductForm.title, description: addProductForm.description, rowsTable: rowsTable
        })
            .then(response => {
                if (response.data['error'] ? true : false) {
                    console.log(response.data['error'])
                } else {
                    setSuccess(response.data['message'])
                }
            })
            .catch(error => {
                console.log(error);
            });

    }
    const handleChangeAddProductForm = (type, e) => {
        setSuccess('')
        const newForm = {
            ...addProductForm, [type]: e.target.value
        }
        setAddProductForm(newForm)
    }
    const handleChangeStateProduct = (product, type, e) => {
        const findIndexProduct = stateProducts.findIndex(el => el.id === product.id);
        if (findIndexProduct !== -1) {
            const updatedProducts = [...stateProducts];
            updatedProducts[findIndexProduct][type] = e.target.value;
            setStateProducts(updatedProducts);
        }
    };
    const acceptUpdating = (productId, e) => {
        e.preventDefault()
        let storeProduct = stateProducts.find((store, i) => store.id === productId)
        let newObj = { ...storeProduct }
        newObj.rowsTable = JSON.parse(storeProduct.rowsTable).concat(rowsTable)
        const result = window.confirm("Вы уверены, что хотите изменить продукт?");
        if (result) {
            axios.put(`https://poghosty.ru/api/poghosty-adminUpdateProduct.php`, {
                id: productId, name: storeProduct.name, size: storeProduct.size, cost: storeProduct.cost, type: storeProduct.second_level_type, season: storeProduct.first_level_season,
                gender: storeProduct.gender, namePhoto: storeProduct.photo, countPhoto: storeProduct.countPhoto, date: storeProduct.date,
                popularity: storeProduct.popularity, article: storeProduct.article, title: storeProduct.title, description: storeProduct.description, rowsTable: newObj.rowsTable
            })
                .then(response => {
                    if (response.data['error'] ? true : false) {
                        console.log(response.data['error'])
                    } else {
                        setSuccess(response.data['message'])
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {

        }
    }
    const deleteProduct = (productId, e) => {
        e.preventDefault()
        const storeProduct = stateProducts && stateProducts.filter((store, i) => store.id !== productId)
        const result = window.confirm("Вы уверены, что хотите удалить продукт?");
        if (result) {
            axios.delete(`https://poghosty.ru/api/poghosty-adminUpdateProduct.php?id=${productId}`)
                .then(response => {
                    if (response.data['error'] ? true : false) {
                        console.log(response.data['error'])
                    } else {
                        alert(response.data['message'])
                        setStateProducts(storeProduct)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            setSuccess('')
        }
    }
    const handleAddRow = () => {
        if (isOpenSizesTable === 'jacket') {
            const newRow = [...rowsTable, ['', '', '', '', '']]
            setRowsTable(newRow)
        }
        if (isOpenSizesTable === 'trousers') {
            const newRow = [...rowsTable, ['', '', '', '']]
            setRowsTable(newRow)
        }
    }
    const handleChangeRowValues = (i, idx, e) => {
        const newRowValue = [...rowsTable]
        newRowValue[i][idx] = e.target.value
        setRowsTable(newRowValue)
    }
    const handleUpdateRowValues = (i, index, idx, e) => {
        const newState = [...stateProducts]
        let newArr = JSON.parse(newState[i]?.rowsTable)
        newArr[index][idx] = e.target.value;
        newState[i] = { ...newState[i], rowsTable: JSON.stringify(newArr) };
        setStateProducts(newState);
    }
    const handleDeleteRow = (i, index, type) => {
        if (type === 'db') {
            const newState = [...stateProducts]
            const obj = newState[i]
            const newArr = JSON.parse(obj.rowsTable).filter((_, idx) => idx !== index)
            obj.rowsTable = JSON.stringify(newArr)
            setStateProducts(newState)
        }
        if (type === 'state') {
            const newRowsTable = [...rowsTable]
            const newArr = newRowsTable.filter((_, i) => i !== index)
            setRowsTable(newArr)
        }
    }
    const handleChangeStatus = (id, e) => {
        const orders = [...stateOrders]
        orders[id].status = e.target.value
        setStateID(id)
        setStatusIsChanged(false)
        setStateOrders(orders)
    }
    const handleSendNewStatus = (id, orderId, e) => {
        e.preventDefault()
        setStateID(id)
        axios.put(`https://poghosty.ru/api/poghosty-changeStatus.php`, { id: orderId, status: stateOrders[id].status })
            .then(response => {
                response.data['success'] === 'Статус изменен!' ? setStatusIsChanged(true) : setStatusIsChanged(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div className="w-full flex flex-col items-center justify-center p-2 text-black ">
            <div className="text-white flex flex-col items-center text-[32px] w-full">
                <button className="border-4 rounded-md p-8 w-[100%]  flex items-center hover:bg-white/20 " onClick={() => { handleOpenMenu('addProduct') }}><MdAddCircle className="mr-4 text-[50px]" />Добавить продукт</button>
                {<form className={`max-h-[0px] overflow-hidden transition-all ease-in-out duration-500 ${openMenu['addProduct'] ? `max-h-[1500px]` : ``} flex flex-col 2xl:w-1/2 w-[90%] my-3 text-[20px] text-black `}>
                    <label className="text-white mt-4">Название</label>
                    <input type="text" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: Рубашка Poghosty' value={addProductForm.name} onChange={(e) => { handleChangeAddProductForm('name', e) }} />

                    <label className="text-white">Размер</label>
                    <input type="number" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: 46 ' value={addProductForm.size} onChange={(e) => handleChangeAddProductForm('size', e)} />

                    <label className="text-white">Стоимость</label>
                    <input type="number" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: 2500 ' value={addProductForm.cost} onChange={(e) => handleChangeAddProductForm('cost', e)} />

                    <label className="text-white">Тип одежды</label>
                    <div className="mb-2 flex justify-between">
                        <input type="text" className='text-black border-none outline-none p-1 w-[40%]' placeholder='Например: Рубашки ' value={addProductForm.type} onChange={(e) => handleChangeAddProductForm('type', e)} />
                        <select name="" id="" className="w-[40%]" onChange={(e) => handleChangeAddProductForm('type', e)}>
                            <option className="">Выбрать тип</option>
                            {levels.second_level.map((second_level, i) => {
                                return <option key={i}>{second_level}</option>
                            })
                            }
                        </select>
                    </div>

                    <label className="text-white">Сезон</label>
                    <div className="mb-2 flex justify-between">
                        <input type="text" className='text-black border-none outline-none p-1 w-[40%]' placeholder='Например: Зимняя одежда ' value={addProductForm.season} onChange={(e) => handleChangeAddProductForm('season', e)} />
                        <select name="" id="" className="w-[40%]" onChange={(e) => handleChangeAddProductForm('season', e)}>

                            <option>Выбрать сезон</option>
                            {levels.first_level.map((first_level, i) => {
                                return <option key={i}>{first_level}</option>
                            })
                            }
                        </select>
                    </div>

                    <label className="text-white">Пол</label>
                    <div className="mb-2 w-full flex justify-start">
                        <select name="" id="" className="w-[40%] h-[36px]" value={addProductForm.gender} onChange={(e) => handleChangeAddProductForm('gender', e)}>
                            <option className="w-1/2 text-center">m</option>
                            <option className="w-1/2 text-center">w</option>
                        </select>
                    </div>

                    <label className="text-white">Название фото</label>
                    <input type="text" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: model1 ' value={addProductForm.namePhoto} onChange={(e) => handleChangeAddProductForm('namePhoto', e)} />

                    <label className="text-white">Количество фото</label>
                    <input type="number" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: 10  (эта цифра - это порядковый номер фотографий)' value={addProductForm.countPhoto} onChange={(e) => handleChangeAddProductForm('countPhoto', e)} />

                    <label className="text-white">Дата выпуска</label>
                    <div className="flex-col w-full justify-start">
                        <div className="flex mb-2">
                            <label className="text-white pr-2 w-[80px]">День:</label>
                            <input type="number" className=' text-black border-none outline-none p-1 w-[100px] mr-4' value={addProductForm.day} onChange={(e) => handleChangeAddProductForm('day', e)} />
                        </div>
                        <div className="flex mb-2">
                            <label className="text-white pr-2 w-[80px]">Месяц:</label>
                            <input type="number" className=' text-black border-none outline-none p-1 w-[100px] mr-4' value={addProductForm.month} onChange={(e) => handleChangeAddProductForm('month', e)} />
                        </div>
                        <div className="flex mb-2">
                            <label className="text-white pr-2 w-[80px]">Год:</label>
                            <input type="number" className=' text-black border-none outline-none p-1 w-[100px] mr-4' value={addProductForm.year} onChange={(e) => handleChangeAddProductForm('year', e)} />
                        </div>
                    </div>

                    <label className="text-white">Популярность</label>
                    <input type="number" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: 4' value={addProductForm.popularity} onChange={(e) => handleChangeAddProductForm('popularity', e)} />

                    <label className="text-white">Артикул</label>
                    <input type="text" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: 7A1FCTG44' value={addProductForm.article} onChange={(e) => handleChangeAddProductForm('article', e)} />

                    <label className="text-white">Заголовок</label>
                    <input type="text" className='mb-2 text-black border-none outline-none p-1' placeholder='Например: Мужская рубашка для прогулок в теплую погоду' value={addProductForm.title} onChange={(e) => handleChangeAddProductForm('title', e)} />

                    <label className="text-white">Описание товара</label>
                    <input type="text" className='mb-2 text-black border-none outline-none p-1' placeholder='' value={addProductForm.description} onChange={(e) => handleChangeAddProductForm('description', e)} />

                    <div className="text-white flex justify-center items-center text-[36px] my-4">
                        <GiMonclerJacket onClick={() => { setIsOpenSizesTable('jacket'); setRowsTable([]) }} className={`cursor-pointer ${isOpenSizesTable === 'jacket' ? `scale-125` : 'scale-100'}`} />
                        <GiTrousers onClick={() => { setIsOpenSizesTable('trousers'); setRowsTable([]) }} className={`cursor-pointer ${isOpenSizesTable === 'trousers' ? `scale-125` : 'scale-100'}`} />
                    </div>
                    {isOpenSizesTable === 'jacket' &&
                        <table className="text-black bg-gray-200 w-full">
                            <thead>
                                <tr>
                                    <th className="leading-5 border-r border-b border-black p-2">Размер</th>
                                    <th className="leading-5 border-r border-b border-black p-2">РФ размер</th>
                                    <th className="leading-5 border-r border-b border-black p-2">Обхват груди</th>
                                    <th className="leading-5 border-r border-b border-black p-2">Обхват талии</th>
                                    <th className="leading-5 border-black border-b p-2">Обхват бедер</th>
                                </tr>
                            </thead>
                            <tbody>

                                {rowsTable.length !== 0 && rowsTable.map((row, index) => {
                                    return <tr key={index} className="">
                                        {row.map((td, idx) => {
                                            return <td key={idx} className="text-center border-r border-black border-b w-[20%]">
                                                <input type="text" className="text-black w-full text-center" value={td} onChange={(e) => { handleChangeRowValues(index, idx, e); setSuccess('') }} />
                                            </td>
                                        })}
                                        <td className="flex items-center justify-center h-[36px] cursor-pointer" onClick={(e) => { e.preventDefault(); handleDeleteRow('', index, 'state',) }}><p><MdDeleteForever /></p></td>
                                    </tr>
                                })}
                                <tr className="w-full" >
                                    <td colSpan={5} className="p-1">
                                        <button className="flex justify-center items-center text-center w-full" onClick={(e) => { e.preventDefault(); handleAddRow() }}><MdAddCircle className="text-[32px]" /></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>}
                    {isOpenSizesTable === 'trousers' &&
                        <table className="text-black bg-gray-200 w-full">
                            <thead>
                                <tr>
                                    <th className="leading-5 border-r border-b border-black p-2">Размер</th>
                                    <th className="leading-5 border-r border-b border-black p-2">РФ размер</th>
                                    <th className="leading-5 border-r border-b border-black p-2">Обхват талии</th>
                                    <th className="leading-5 border-black border-b p-2">Обхват бедер</th>
                                </tr>
                            </thead>
                            <tbody>

                                {rowsTable.length !== 0 && rowsTable.map((row, index) => {
                                    return <tr key={index} className="">
                                        {row.map((td, idx) => {
                                            return <td key={idx} className="text-center border-r border-black border-b w-[25%]">
                                                <input type="text" className="text-black w-full text-center" value={td} onChange={(e) => { handleChangeRowValues(index, idx, e); setSuccess('') }} />
                                            </td>
                                        })}
                                        <td className="flex items-center justify-center h-[36px] cursor-pointer" onClick={(e) => { e.preventDefault(); handleDeleteRow('', index, 'state',) }}><p><MdDeleteForever /></p></td>
                                    </tr>
                                })}
                                <tr className="w-full" >
                                    <td colSpan={5} className="p-1">
                                        <button className="flex justify-center items-center text-center w-full" onClick={(e) => { e.preventDefault(); handleAddRow() }}><MdAddCircle className="text-[32px]" /></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>}
                    <button className="w-full text-white border-2 bg-emerald-400 mt-8 p-2 text-[20px] mb-8" onClick={(e) => sendAddProductForm(e)}>Добавить одежду</button>
                    {success === 'Продукт успешно добавлен!' && <p className="text-emerald-400 text-center pb-4">{success}</p>}
                </form>}
                <button className="border-4 rounded-md p-8 w-[100%] flex items-center hover:bg-white/20" onClick={() => { handleOpenMenu('changeProduct') }}><MdOutlineChangeCircle className="mr-4 text-[50px]" />Изменить продукт</button>
                <div className={`max-h-[0px] overflow-hidden transition-all ease-in-out duration-500 bg-white my-3 text-[20px] w-[100%] 2xl:w-[80%] rounded-md`} style={{ maxHeight: `${openMenu['changeProduct'] ? props.products.length * 1000 : 0}px` }}>
                    {
                        props.products && props.products.map((product, i) => {
                            return <div key={product.id} className=" mb-2 flex border-b border-black p-8 items-center justify-center ">
                                <img src={`${process.env.PUBLIC_URL}/img/products/${product.photo}/${product.photo}_1.jpg`} alt={`${product.photo}`} className="w-[20%] h-[20%] rounded-[10px] mx-4" />
                                <div className="flex flex-col  w-full rounded-[10px] px-2 ">
                                    <div className="flex flex-col">

                                        <div className="flex" onClick={() => setStateID(product.id)}>
                                            <div className="flex w-[55%]">
                                                <h2 className="bg-black text-white mb-1 p-1 rounded-md w-full  text-nowrap px-2"><b>Название: </b></h2>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.name || ''} onChange={(e) => { handleChangeStateProduct(product, 'name', e); setSuccess('') }}></input>
                                        </div>

                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">
                                                <h2 className="bg-black text-white mb-1 p-1 rounded-md w-full  px-2 text-nowrap">id(нельзя поменять): </h2>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.id || ''} onChange={(e) => { handleChangeStateProduct(product, 'id', e); setSuccess('') }}></input>
                                        </div>

                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">
                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Размер: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.size || ''} onChange={(e) => { handleChangeStateProduct(product, 'size', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)}>
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Цена: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.cost || ''} onChange={(e) => { handleChangeStateProduct(product, 'cost', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)}>
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Сезон: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.first_level_season || ''} onChange={(e) => { handleChangeStateProduct(product, 'first_level_season', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Тип: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.second_level_type || ''} onChange={(e) => { handleChangeStateProduct(product, 'second_level_type', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)}>
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap" > Гендер: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.gender || ''} onChange={(e) => { handleChangeStateProduct(product, 'gender', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Название фото: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.photo || ''} onChange={(e) => { handleChangeStateProduct(product, 'photo', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Количество фото: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.countPhoto || ''} onChange={(e) => { handleChangeStateProduct(product, 'countPhoto', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Дата выпуска: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.date || ''} onChange={(e) => { handleChangeStateProduct(product, 'date', e); setSuccess('') }}></input>

                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">

                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Популярность: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.popularity || ''} onChange={(e) => { handleChangeStateProduct(product, 'popularity', e); setSuccess('') }}></input>
                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">
                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Артикул: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.article || ''} onChange={(e) => { handleChangeStateProduct(product, 'article', e); setSuccess('') }}></input>
                                        </div>
                                        <div className="flex" onClick={() => setStateID(product.id)} >
                                            <div className="flex w-[55%]">
                                                <p className="bg-black text-white mb-1 p-1 rounded-md  w-full px-2 text-nowrap">Описание: </p>
                                            </div>
                                            <input type="text" className="bg-white border border-black text-black w-full px-2" value={stateProducts[i]?.description || ''} onChange={(e) => { handleChangeStateProduct(product, 'description', e); setSuccess('') }}></input>
                                        </div>
                                    </div>
                                    <div className="text-black flex justify-center items-center text-[36px] my-4">
                                        <GiMonclerJacket onClick={() => { setIsOpenSizesTable('jacket'); setRowsTable([]); setStateID(product.id) }} className={`cursor-pointer ${isOpenSizesTable === 'jacket' && stateProducts[i]?.rowsTable && stateID === product.id ? `scale-125` : 'scale-100'}`} />
                                        <GiTrousers onClick={() => { setIsOpenSizesTable('trousers'); setRowsTable([]); setStateID(product.id) }} className={`cursor-pointer ${isOpenSizesTable === 'trousers' && stateProducts[i]?.rowsTable && stateID === product.id ? `scale-125` : 'scale-100'}`} />
                                    </div>
                                    {isOpenSizesTable === 'jacket' && stateID === product.id &&
                                        < table className="text-black bg-gray-200 w-full">
                                            <thead>
                                                <tr>
                                                    <th className="leading-5 border-r border-b border-black p-2">Размер</th>
                                                    <th className="leading-5 border-r border-b border-black p-2">РФ размер</th>
                                                    <th className="leading-5 border-r border-b border-black p-2">Обхват груди</th>
                                                    <th className="leading-5 border-r border-b border-black p-2">Обхват талии</th>
                                                    <th className="leading-5 border-black border-b p-2">Обхват бедер</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {stateProducts[i]?.rowsTable && JSON.parse(stateProducts[i].rowsTable)?.length > 0 && JSON.parse(stateProducts[i].rowsTable)[0].length === 5 && JSON.parse(stateProducts[i].rowsTable).map((row, index) => {
                                                    return <tr key={index} className="">
                                                        {Array.from({ length: row.length }, (_, idx) => {
                                                            return <td key={idx} className="text-center border-r border-black border-b w-[20%]">
                                                                <input type="text" className="text-black w-full text-center" value={JSON.parse(stateProducts[i]?.rowsTable)[index][idx] || ''} onChange={(e) => { handleUpdateRowValues(i, index, idx, e); setSuccess('') }} />
                                                            </td>
                                                        })}
                                                        <td className="flex items-center justify-center h-[36px] cursor-pointer" onClick={(e) => { e.preventDefault(); handleDeleteRow(i, index, 'db') }}><p><MdDeleteForever /></p></td>
                                                    </tr>
                                                })}
                                                {isOpenSizesTable && rowsTable.length !== 0 && rowsTable.map((row, index) => {
                                                    return <tr key={index} className="">
                                                        {row.map((td, idx) => {
                                                            return <td key={idx} className="text-center border-r border-black border-b w-[20%]">
                                                                <input type="text" className="text-black w-full text-center" value={td} onChange={(e) => { handleChangeRowValues(index, idx, e); setSuccess('') }} />
                                                            </td>
                                                        })}
                                                        <td className="flex items-center justify-center h-[36px] cursor-pointer" onClick={(e) => { e.preventDefault(); handleDeleteRow(i, index, 'state',) }}><p><MdDeleteForever /></p></td>
                                                    </tr>
                                                })}
                                                <tr className="w-full" >
                                                    <td colSpan={5} className="p-1">
                                                        <button className="flex justify-center items-center text-center w-full" onClick={(e) => { e.preventDefault(); handleAddRow() }}><MdAddCircle className="text-[32px]" /></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    {isOpenSizesTable === 'trousers' && stateID === product.id &&
                                        <table className="text-black bg-gray-200 w-full">
                                            <thead>
                                                <tr>
                                                    <th className="leading-5 border-r border-b border-black p-2">Размер</th>
                                                    <th className="leading-5 border-r border-b border-black p-2">РФ размер</th>
                                                    <th className="leading-5 border-r border-b border-black p-2">Обхват талии</th>
                                                    <th className="leading-5 border-black border-b p-2">Обхват бедер</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stateProducts[i]?.rowsTable && JSON.parse(stateProducts[i].rowsTable)?.length > 0 && JSON.parse(stateProducts[i].rowsTable)[0].length === 4 && JSON.parse(stateProducts[i].rowsTable).map((row, index) => {
                                                    return <tr key={index} className="">
                                                        {Array.from({ length: row.length }, (_, idx) => {
                                                            return <td key={idx} className="text-center border-r border-black border-b h-[36px] w-[25%]">
                                                                <input type="text" className="text-black w-full text-center h-[36px]" value={JSON.parse(stateProducts[i]?.rowsTable)[index][idx] || ''} onChange={(e) => { handleUpdateRowValues(i, index, idx, e); setSuccess('') }} />
                                                            </td>
                                                        })}
                                                        <td className="flex items-center justify-center h-[36px] cursor-pointer" onClick={(e) => { e.preventDefault(); handleDeleteRow(i, index, 'db') }}><p><MdDeleteForever /></p></td>
                                                    </tr>
                                                })}
                                                {isOpenSizesTable && rowsTable.map((row, index) => {
                                                    return <tr key={index} className="">
                                                        {row.map((td, idx) => {
                                                            return <td key={idx} className="text-center border-r border-black border-b h-[36px] w-[25%]">
                                                                <input type="text" className="text-black w-full text-center h-[36px]" value={td} onChange={(e) => { handleChangeRowValues(index, idx, e); setSuccess('') }} />
                                                            </td>
                                                        })}
                                                        <td className="flex items-center justify-center h-[36px] cursor-pointer" onClick={(e) => { e.preventDefault(); handleDeleteRow(i, index, 'state',) }}><p><MdDeleteForever /></p></td>
                                                    </tr>
                                                })}
                                                <tr className="w-full" >
                                                    <td colSpan={5} className="p-1">
                                                        <button className="flex justify-center items-center text-center w-full" onClick={(e) => { e.preventDefault(); handleAddRow() }}><MdAddCircle className="text-[32px]" /></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    <button className="text-white text-center border-2 rounded-md h-[60px] p-2 leading-5 my-2 mt-4 bg-emerald-400" onClick={(e) => { acceptUpdating(product.id, e); setStateID(product.id) }}>Применить изменения</button>
                                    <button className="text-white border-2 rounded-md h-[60px] p-2 leading-5 my-2 mt-4 bg-gray-200 flex justify-center items-center text-[36px]" onClick={(e) => deleteProduct(product.id, e)}><MdDeleteForever className="text-red-500" /></button>
                                    {success === 'Продукт успешно обновлен!' && stateID === product.id && <p className="text-emerald-400 text-center">{success}</p>}
                                </div>
                            </div>
                        })
                    }
                </div>
                <button className="border-4 rounded-md p-8 w-[100%]  flex items-center hover:bg-white/20  mb-3" onClick={() => { handleOpenMenu('currentOrders') }}><TbCurrentLocation className="mr-4 text-[50px]" />Текущие заказы</button>
                {<div className={`max-h-[0px] transition-all overflow-hidden ease-in-out duration-500 w-full`} style={{ maxHeight: `${openMenu['currentOrders'] ? stateOrders.length * 2000 : 0}px` }}>
                    <div className={`text-black w-[100%] ${stateOrders.length === 0 ? 'h-[40vh]' : ''} p-8 rounded-md bg-white`}>
                        <h1 className="text-center text-[36px] mb-6">Заказы</h1>
                        {
                            stateOrders.length > 0 && stateOrders.map((order, id) => {
                                return <div key={id} className="border-2 border-black my-4 p-4 rounded-lg">
                                    <div>
                                        <h5 className="font-semibold text-nowrap text-xl leading-8 text-black max-[550px]:text-center pr-4">Заказ № {order.number}</h5>
                                        <div className="sm:flex pl-[20px]">
                                            <p className="font-normal text-lg leading-4 text-black my-2 min-[550px]:my-1 max-[550px]:text-center pr-2 text-nowrap"><b>Дата заказа:</b> </p>
                                            <p className="pr-4 my-1 leading-4 text-[18px]">{order.date.split(' ')[0].split('-')[2]}.{order.date.split(' ')[0].split('-')[1]}.{order.date.split(' ')[0].split('-')[0]} {order.date.split(' ')[1]} </p>
                                            <p className="w-full my-1 leading-4 text-[18px]">{order.time} </p>
                                        </div>
                                        <h6 className="pl-[20px] text-lg leading-tight text-black  max-[550px]:text-center"><b>Имя:</b> {order.name + "(" + (order.user === "Не зарегистрирован" ? '' : "id: ") + order.user + ")"}</h6>
                                        <h6 className="pl-[20px]  text-lg leading-tight text-black  max-[550px]:text-center"><b>Телефон:</b> {order.phone}</h6>
                                        <h6 className="pl-[20px]  text-lg leading-tight text-black  max-[550px]:text-center"><b>Почта:</b> {order.email}</h6>
                                        <h6 className=" pl-[20px]  text-lg leading-tight text-black  max-[550px]:text-center"><b>Адрес:</b> {order.city + ', ' + order.address}</h6>
                                    </div>
                                    <div className="sm:flex gap-2">
                                        <input type="text" className="px-[20px] w-full font-bold text-lg leading-tight text-black  max-[550px]:text-center" value={order.status || ''} onChange={(e) => handleChangeStatus(id, e)}></input>
                                        <button className="text-black border-2 border-black rounded-md p-2 text-[18px] font-bold text-nowrap" onClick={(e) => handleSendNewStatus(id, order.number, e)}>Изменить статус</button>
                                    </div>
                                    {statusIsChanged && stateID === id && <h1 className="text-emerald-400 text-[18px] font-bold">Статус изменен!</h1>}

                                    {order.map((product, id) => {
                                        return <div key={id} className={`item${id}cart grid grid-cols-1 lg:grid-cols-2 min-[550px]:gap-6 border-t border-gray-200 py-6 pl-[20px]`}>
                                            <div className="flex items-center flex-col min-[550px]:flex-row gap-3 min-[550px]:gap-6 w-full max-xl:justify-center max-xl:max-w-xl max-xl:mx-auto">
                                                <Link to={`/product/${product.id}`} className="img-box" onClick={() => { handleProductLink(product.id) }}>
                                                    <img src={`${process.env.PUBLIC_URL}/img/products/${product.photo}/${product.photo}_1.jpg`} alt={`${product.photo}`} className="w-auto rounded-xl object-fill max-h-[150px]" />
                                                </Link>
                                                <div className="pro-data w-full p-4 max-w-sm hover:bg-gray-200/50 rounded-[20px]">
                                                    <Link to={`/product/${product.id}`} className='flex' onClick={() => { handleProductLink(product.id) }}>
                                                        <h5 className="font-semibold text-nowrap text-xl leading-8 text-black max-[550px]:text-center pr-4">{product.name} </h5>
                                                        <p className="italic text-xl leading-8 text-black max-[550px]:text-center">{product.gender === 'm' ? 'Мужчины' : product.gender === 'w' ? 'Женщины' : ''}</p>
                                                    </Link>

                                                    <div className="flex max-w-[350px] relative">
                                                        <p className="font-normal text-lg leading-4 text-gray-500 my-2 min-[550px]:my-1 max-[550px]:text-center pr-2">Размер: </p>
                                                        <p className="w-full my-1 leading-4 text-[18px]">{product.choice} </p>
                                                    </div>
                                                    <div className="flex max-w-[350px] relative">
                                                        <p className="font-normal text-lg leading-4 text-gray-500 my-2 min-[550px]:my-1 max-[550px]:text-center pr-2">Количество: </p>
                                                        <p className="w-full my-1 leading-4 text-[18px]">{product.count} </p>
                                                    </div>
                                                    <h6 className="font-medium text-lg leading-8 text-indigo-600  max-[550px]:text-center">Полная цена: {+product.cost * +product.count} ₽</h6>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>}
                <button className="border-4 rounded-md p-8 w-[100%]  flex items-center hover:bg-white/20 mt-3" onClick={() => { handleOpenMenu('allUsers') }}><GoPerson className="mr-4 text-[50px]" />Все пользователи</button>
                {<div className={`max-h-[0px] overflow-hidden transition-all ease-in-out duration-500 w-full `} style={{ maxHeight: `${openMenu['allUsers'] ? 1000 * stateUsers.length : 0}px` }}>
                    {users.registered.map((user, i) => (
                        <div className="flex flex-col w-full my-3 text-[20px] text-black items-center mt-8" key={i}>
                            <p className="text-white">{user.name} id: {user.id}</p>
                            <div className=" mb-2 md:flex-row flex flex-col border-b border-gray-200 pb-[8px] p-2 rounded-md bg-white w-[80%]">
                                <div className="flex flex-col w-full rounded-[10px] px-2 py-2 ">
                                    <div className="flex">
                                        <h2 className="pr-2"><b>{user.name}</b></h2>
                                    </div>
                                    <p>id: <strong>{user.id}</strong></p>
                                    <p>Логин: <strong>{user.login}</strong></p>
                                    <p>Пароль: <strong>{user.password}</strong></p>
                                    <p className="overflow-scroll">Корзина: <br /><strong>{JSON.parse(user.cart).map(el => <><span>id:{el.id} {el.name} Размер: {el.choice}, </span><br /></>)}</strong></p>
                                    <p className="overflow-scroll">Избранное: <br /><strong>{JSON.parse(user.favorites).map(el => <><span>id:{el.id} {el.name}, </span><br /></>)}</strong></p>
                                    <p>Город: <strong>{user.city}</strong></p>
                                    <p>Адрес: <strong>{user.address}</strong></p>
                                    <p>Телефон: <strong>{user.phone}</strong></p>
                                    <p>Email: <strong>{user.email}</strong></p>
                                    <p>Дата регистрации: <strong>{user.registrationDate.split('-')[2]}.{user.registrationDate.split('-')[1]}.{user.registrationDate.split('-')[0]}</strong></p>
                                    <p>Последний вход: <strong>{user.lastDateVisit.split('-')[2]}.{user.lastDateVisit.split('-')[1]}.{user.lastDateVisit.split('-')[0]} | {user.lastTimeVisit}</strong></p>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>}
            </div >
        </div >

    );
};

export default Admin;
