import { useState } from 'react';
const Oplata  = () => {
    const [target, setTarget] = useState('')
  return (
    <>
          <div>
              <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '2.1') setTarget('2.1'); else { setTarget('') } }}>Способы оплаты</h4>
              <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '2.1' ? `max-h-[1500px] p-4` : ''}`}>Оплатить заказ можно банковскими картами: Visa, Mastercard, Мир, Webmoney,<br />
                  <p>Оплатить можно как через сайт, следуйте инструкции. Или же переводом на СберБанк или Т-банк.</p>
              </div>
          </div>
          <div>
              <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '2.2') setTarget('2.2'); else { setTarget('') } }}>Подтверждение оплаты</h4>
              <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '2.2' ? `max-h-[1500px] p-4` : ''}`}>
                  <p>После оформления заказа ожидайте до 2 рабочих дней</p>
              </div>
          </div>
    </>
  );
};

export default Oplata ;
