import { useState } from 'react';
const Bezopasnost = () => {
    const [target, setTarget] = useState('')
    return (
        <>
            <div>
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '4.1') setTarget('4.1'); else { setTarget('') } }}>Политика конфиденциальности</h4>
                <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '4.1' ? `max-h-[1500px] p-4` : ''}`}>
                    <p>1.1. Мы не осуществляем сбор ваших персональных данных с использованием Сайта.</p>
                    <p>1.2. Все данные, собираемые на Сайте, предоставляются и принимаются в обезличенной форме (далее – «Обезличенные данные»).</p>
                    <p>1.3. Обезличенные данные включают следующие сведения, которые не позволяют вас идентифицировать:</p>
                    <p>1.3.1. Информацию, которую вы предоставляете о себе самостоятельно с использованием онлайн-форм и программных модулей Сайта, включая имя и номер телефона и/или адрес электронной почты.</p>
                    <p>1.3.2. Данные, которые передаются в обезличенном виде в автоматическом режиме в зависимости от настроек используемого вами программного обеспечения.</p>
                    <p>1.4. Администрация вправе устанавливать требования к составу Обезличенных данных Пользователя, которые собираются использованием Сайта.</p>
                    <p> 1.5. Если определенная информация не помечена как обязательная, ее предоставление или раскрытие осуществляется Пользователем на свое усмотрение. Одновременно вы даете информированное согласие на доступ неограниченного круга лиц к таким данным. Указанные данные становится общедоступными с момента предоставления и/или раскрытия в иной форме.</p>
                    <p>  1.6. Администрация не осуществляет проверку достоверности предоставляемых данных и наличия у Пользователя необходимого согласия на их обработку в соответствии с настоящей Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий на ее использование.</p>
                    <p> 1.7. Вы осознаете и принимаете возможность использования на Сайте программного обеспечения третьих лиц, в результате чего такие лица могут получать и передавать указанные в п.1.3 данные в обезличенном виде.</p>
                    <p> 1.8. Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:<br />
                        •	данные браузера (тип, версия, cookie);<br />
                        •	данные устройства и место его положения;<br />
                        •	данные операционной системы (тип, версия, разрешение экрана);<br />
                        •	данные запроса (время, источник перехода, IP-адрес).</p>
                    <p> 1.9. Администрация не несет ответственность за порядок использования Обезличенных данных Пользователя третьими лицами.</p>

                </div>
            </div>
            <div className='mb-4'>
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '4.2') setTarget('4.2'); else { setTarget('') } }}>Политика безопасности</h4>
                <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '4.2' ? `max-h-[1500px] p-4` : ''}`}>
                    <p>Бренд «poGHOSTy» гарантирует, что предоставленная Вами информация не будет предоставлена третьему лицу, за исключением случаев, предусмотренных законодательством Российской Федерации.

                        В определенных обстоятельствах компания «poGHOSTy» может попросить Вас предоставить личные данные при регистрации. Предоставленная информация используется при оформлении заказа в Интернет-магазине, отслеживании статуса заказа и общении с менеджерами.

                        Личные сведения можно изменить, обновить или удалить в любое время в разделе Личный кабинет.

                        Бренд «poGHOSTy» с Вашего согласия может присылать на указанный при регистрации адрес электронной почты сообщения.

                        Нажимая кнопку оформления заказа, вы автоматически соглашаетесь с политикой безопасности.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Bezopasnost;
