import { Link } from "react-router-dom";

const Contacts = () => {
    return (
        <>
            <div className="flex flex-col">
                <h1 className='pt-[10px]'><strong>Телефон:</strong> <Link to={'tel:+79999682354'}>+7(999)968-23-54</Link></h1>
                <h1 className='pt-[10px]'><strong>E-mail:</strong> <Link to={'mailto:poghosty.russia@gmail.com'}>poghosty.russia@gmail.com</Link></h1>
                <h1 className='pt-[10px] flex'><strong>Соцсети:ㅤ</strong>
                    <Link to={'https://t.me/po_gHost_y'} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/social/telegram.svg`} alt='socialTG' className='pr-2 cursor-pointer h-[34px] w-auto'></img>
                    </Link>
                    <Link to={'https://wa.me/79999682354'} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/social/whatsUp.svg`} alt='socialWhatsUp' className='pr-2 cursor-pointer h-[34px] w-auto'></img>
                    </Link>
                    <Link to={'https://vk.com/club224180955'} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/social/vk.svg`} alt='socialVK' className=' cursor-pointer h-[34px] w-auto'></img>
                    </Link></h1>
                <h1 className='pt-[10px]'><strong>ИНН:</strong> 252200488509</h1>
                <h1 className='pt-[10px]'><strong>ФИО предпринимателя:</strong> Раев Павел Алексеевич</h1>
            </div>

        </>
    );
};

export default Contacts;
