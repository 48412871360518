import axios from "axios";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
const Success = ({ setCart, user, setUser }) => {
    const animationData = require('../../assets/success.json');
    const [isSuccess, setIsSuccess] = useState(false)
    const [orderNumber, setOrderNumber] = useState('')
    useEffect(() => {
        axios.get(`https://poghosty.ru/api/poghosty-checkPaymentResult.php?order_id=${localStorage.getItem('paymentId')}`)
            .then(res => {
                if (res.data['receipt']['status'] === 'succeeded') {
                    setIsSuccess(true)
                    setOrderNumber(res.data['receipt']['metadata']['order_id'])
                    if (user) {
                        axios.put('https://poghosty.ru/api/poghosty-userRemoveCart.php', { login: user.login, password: user.password })
                            .then(response => {
                                localStorage.setItem('user', JSON.stringify(response.data['user']))
                                setUser(response.data['user'])
                            })
                    }
                    axios.post('https://poghosty.ru/api/poghosty-getEmail.php', { id: res.data['receipt']['metadata']['order_id'] })
                        .then(resp => {
                            axios.post('https://poghosty.ru/api/poghosty-sendEmail.php', { email: resp.data['orders'][0]['email'], orderId: res.data['receipt']['metadata']['order_id'] })
                                .then(result => {
                                    setCart([])
                                    localStorage.removeItem('paymentId')
                                    localStorage.removeItem('cart')
                                })
                        })
                } else if (res.data['receipt']['status'] === 'waiting_for_capture') {
                    setIsSuccess(false)
                    localStorage.removeItem('paymentId')
                }
                else if (res.data['receipt']['status'] === 'canceled') {
                    setIsSuccess(false)
                    localStorage.removeItem('paymentId')
                } else {
                    setIsSuccess(false)
                    localStorage.removeItem('paymentId')
                }
            }).catch(error => {
                console.log(error)
            })
    }, [])
    return (
        <div className="w-full text-black bg-white rounded-md flex flex-col items-center justify-center">
            {
                isSuccess ?
                    <div className="p-[40px]">
                        <div className="sm:p-4 flex justify-center items-center w-full">
                            <Lottie animationData={animationData} loop={false} className="h-[100px] min-w-[100px]" />
                            <h1 className="text-center leading-tight font-bold sm:text-[36px] text-[22px]">Заказ №{orderNumber} успешно оплачен!</h1>
                        </div>
                        <div className="w-full flex flex-col items-center justify-center mb-[40px]">
                            <p className="mb-2">Мы отправим Вам код для отслеживания посылки, в Telegram и Email, которые вы указали при оформлении заказа.</p>
                            <p className="mb-2">Если Вы зарегистрированы на нашем сайте, то можете посмотреть статус вашего заказа в разделе "Мой профиль" → "Мои заказы".</p>
                            <p className="mb-2">Мы будем информировать Вас, о передвижении и изменении статуса заказа.</p>
                            <p> По всем вопросам обращаться по тел: +7(999)968-23-54 (Максим) </p>
                        </div>
                    </div> :
                    <div className="p-[40px]">
                        <div className="sm:p-4 flex justify-center items-center w-full">
                            <img src={`${process.env.PUBLIC_URL}/img/resources/errorPayment.svg`} alt='errorPayment' className='mr-2 w-[36.11px] h-auto min-w-[36.11px]'></img>
                            <h1 className="text-center leading-tight font-bold sm:text-[36px] text-[22px]">Ошибка при выполнении заказа</h1>
                        </div>
                        <div className="w-full flex flex-col items-center justify-center mb-[40px]">
                            <p className="mb-2">Упс... Что то пошло не так</p>
                            <p className="mb-2"> Если вы зарегистрированы, проверьте статус заказа </p>
                            <p className="mb-2"> Попробуйте заказать снова или свяжитесь с нами - любым удобным способом </p>
                            <p> По всем вопросам обращаться по тел: +7(999)968-23-54 (Максим) </p>
                        </div>
                    </div>
            }
        </div>
    );
};

export default Success;
