import { useCallback, useEffect, useState } from "react";
// import { FaPhone } from "react-icons/fa";
// import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";
const debounce = (fn, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(...args), delay);
    };
};

const Footer = () => {
    const [disableLight, setDisableLight] = useState(localStorage.getItem('light') ? JSON.parse(localStorage.getItem('light')) : false)
    const [verticalScroll, setVerticalScroll] = useState(false)

    const handleScrollToTop = () => {
        setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 300)
    };
    const handleSearchEndPage = useCallback(() => {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
            setVerticalScroll(true)
        } else {
            setVerticalScroll(false)
        }
    }, [])

    const debouncedScrollHandler = debounce(handleSearchEndPage, 70);
    useEffect(() => {
        if (disableLight) {
            let pos = document.documentElement
            const mouseOnMove = (e) => {
                pos.style.setProperty(`--x`, e.clientX + 'px')
                pos.style.setProperty(`--y`, e.clientY + 'px')
            }
            pos.addEventListener('mousemove', mouseOnMove)
            window.addEventListener('scroll', debouncedScrollHandler);

            return () => {
                window.removeEventListener('scroll', debouncedScrollHandler);
                window.removeEventListener('mousemove', mouseOnMove);
            };
        }
    }, [disableLight, debouncedScrollHandler])
    const handleDisableLight = () => {
        localStorage.setItem('light', !disableLight)
        setDisableLight(!disableLight)
    }
    return (

        <footer className="container xl mx-auto px-4 pt-4 relative min-h-[510px] h-[400px] border-t border-t-slate-300 bg-no-repeat bg-center sm:bg-[length:800px_auto] bg-[length:400px_auto]" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/resources/footerImg.jpg)` }}>
            <div className="flex flex-col w-full sm:justify-center items-center">
                <h1 className="text-center font-bold">Интернет-магазин<br />бренда poGHOSTy</h1>
                <div className="mt-4 w-full h-full">
                    <div className="sm:flex-row flex h-[400px] sm:h-auto flex-col justify-between sm:justify-between">
                        <div>
                            <div className="flex items-center flex-col sm:flex-row mb-2">
                                <p>Телефон:ㅤ</p>
                                <Link to={'tel:+79999682354'} className="text-nowrap w-[242px] sm:pr-[26px] text-center sm:w-auto decoration-[1px] underline underline-offset-4">
                                    +7(999)968-23-54
                                </Link>
                            </div>
                            <div className="flex items-center flex-col sm:flex-row">
                                <p>Эл. почта:ㅤ</p>
                                <Link to={'mailto:poghosty.russia@gmail.com'} className="text-nowrap w-[242px] sm:pr-[26px] text-center sm:w-auto decoration-[1px] underline underline-offset-4">
                                    poghosty.russia@gmail.com
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col decoration-[1px] underline underline-offset-4">
                            <Link to={'/help/dostavka'} onClick={() => handleScrollToTop()} className="mb-2">
                                Доставка
                            </Link>
                            <Link to={'/help/oplata'} onClick={() => handleScrollToTop()} className="mb-2">
                                Оплата
                            </Link>
                            <Link to={'/help/vozvrat'} onClick={() => handleScrollToTop()} className="mb-2">
                                Возврат
                            </Link>
                            <Link to={'/help/bezopasnost'} onClick={() => handleScrollToTop()} className="leading-4 mb-2">
                                Политика безопасности<br /> и конфиденциальности
                            </Link>
                            <Link to={'/help/contacts'} onClick={() => handleScrollToTop()} className="mb-2">
                                Контакты
                            </Link>
                        </div>
                    </div>

                </div>

            </div>
            <div className="h-[270px]">
                <div className="xl:flex items-center hidden h-[270px]">
                    <input id="checkbox" className="" type="checkbox" checked={disableLight ? true : false} onChange={() => handleDisableLight()} />
                    <label className="switch" htmlFor="checkbox">
                        <svg viewBox="0 0 512 512" className="slider">
                            <path
                                d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"
                            ></path>
                        </svg>
                    </label>
                </div>
            </div>
            <div className='flex flex-col items-center w-full pb-[8px]'>
                <h1 className="leading-5 pb-2">Я в соцсетях</h1>
                <div className="flex justify-center">
                    <Link to={'https://t.me/po_gHost_y'} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/social/telegram.svg`} alt='socialTG' className='pr-2 cursor-pointer h-[34px] w-auto'></img>
                    </Link>
                    <Link to={'https://wa.me/79999682354'} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/social/whatsUp.svg`} alt='socialWhatsUp' className='pr-2 cursor-pointer h-[34px] w-auto'></img>
                    </Link>
                    <Link to={'https://vk.com/club224180955'} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/social/vk.svg`} alt='socialVK' className=' cursor-pointer h-[34px] w-auto'></img>
                    </Link>
                </div>
            </div>


            <div className="border-t border-t-slate-300 p-2 flex items-end justify-center">
                <h1 className="pr-2 text-nowrap">©️ Poghosty. 2024</h1>
            </div>
            {verticalScroll && disableLight && <div className={`grad fixed top-0 left-0 xl:w-[100%] xl:h-[100%] w-0 h-0 pointer-events-none`}></div>}
        </footer >
    );
};

export default Footer;
