import { useLocation, useNavigate, Link } from 'react-router-dom';
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useEffect, useState } from 'react';
import axios from 'axios';
const Orders = ({ id }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([])
    useEffect(() => {
        axios.post('https://poghosty.ru/api/poghosty-getOrders.php', { id: id })
            .then(res => {
                const arr = res.data['orders'].map(orders => JSON.parse(orders.order))
                arr.forEach((products, i) => [
                    ...products, products.status = res.data['orders'][i]['status'],
                    products.date = res.data['orders'][i]['date'],
                    products.time = res.data['orders'][i]['time'],
                    products.number = res.data['orders'][i]['id'],
                    products.city = res.data['orders'][i]['city'],
                    products.address = res.data['orders'][i]['address'],
                ])
                setOrders(arr)
            }).catch(error => {
                console.log(error)
            })
    }, [id])

    const handleGoBack = () => {
        if (location.state && location.state.prevPath) {
            navigate(location.state.prevPath, { replace: true });
        } else {
            navigate(-1);
        }
    };
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handleProductLink = (id) => {
        localStorage.setItem('productId', id);
        localStorage.setItem('lastPosScroll', window.scrollY);
        handleScrollToTop()
    }
    return (
        <>
            <Link to='#' className="border border-white text-black bg-white p-2 rounded-md text-nowrap inline-flex items-center mb-4" onClick={() => handleGoBack()}><MdOutlineKeyboardBackspace className="text-[20px] text-black" /><b>Назад</b></Link>
            <div className={`text-black w-[100 %] ${orders.length === 0 ? 'h-[40vh]' : ''} p-8 rounded-md bg-white`}>
                <h1 className="text-center text-[36px] mb-6">Заказы</h1>
                {
                    orders && orders.map((order, i) => {
                        return <div key={i} className='border-2 border-black my-4 p-4 rounded-lg'>
                            <h5 className="font-semibold text-nowrap text-xl leading-8 text-black max-[550px]:text-center pr-4">Заказ № {order.number} </h5>
                            <h6 className="font-bold text-lg leading-tight text-black  max-[550px]:text-center">{order.status}</h6>
                            {order.status !== 'Не оплачено' && <h6 className=" text-lg leading-tight text-black  max-[550px]:text-center">{"(" + order.city + ', ' + order.address + ")"}</h6>}

                            {order.map((product, id) => {
                                return <div key={id} className={`item${id}cart grid grid-cols-1 lg:grid-cols-2 min-[550px]:gap-6 border-t border-gray-200 py-6`}>
                                    <div className="flex items-center flex-col min-[550px]:flex-row gap-3 min-[550px]:gap-6 w-full max-xl:justify-center max-xl:max-w-xl max-xl:mx-auto">
                                        <Link to={`/product/${product.id}`} className="img-box" onClick={() => { handleProductLink(product.id) }}>
                                            <img src={`${process.env.PUBLIC_URL}/img/products/${product.photo}/${product.photo}_1.jpg`} alt={`${product.photo}`} className="w-auto rounded-xl object-fill max-h-[150px]" />
                                        </Link>
                                        <div className="pro-data w-full p-4 max-w-sm hover:bg-gray-200/50 rounded-[20px]">
                                            <Link to={`/product/${product.id}`} className='sm:flex' onClick={() => { handleProductLink(product.id) }}>
                                                <h5 className="font-semibold text-nowrap text-xl leading-8 text-black max-[550px]:text-center pr-4">{product.name} </h5>
                                                <p className="italic text-xl leading-8 text-black max-[550px]:text-center">{product.gender === 'm' ? 'Мужчины' : product.gender === 'w' ? 'Женщины' : ''}</p>
                                            </Link>
                                            {order.date && <div className="sm:flex max-w-[350px] relative">
                                                <p className="font-normal text-lg leading-4 text-gray-500 my-2 min-[550px]:my-1 max-[550px]:text-center pr-2 text-nowrap">Дата заказа: </p>
                                                <p className="pr-4 my-1 leading-4">{order.date.split(' ')[0].split('-')[2]}.{order.date.split(' ')[0].split('-')[1]}.{order.date.split(' ')[0].split('-')[0]} {order.date.split(' ')[1]} </p>
                                                <p className="w-full my-1 leading-4">{order.time} </p>
                                            </div>}
                                            <div className="flex max-w-[350px] relative">
                                                <p className="font-normal text-lg leading-4 text-gray-500 my-2 min-[550px]:my-1 max-[550px]:text-center pr-2">Размер: </p>
                                                <p className="w-full my-1 leading-4">{product.choice} </p>
                                            </div>
                                            <div className="flex max-w-[350px] relative">
                                                <p className="font-normal text-lg leading-4 text-gray-500 my-2 min-[550px]:my-1 max-[550px]:text-center pr-2">Количество: </p>
                                                <p className="w-full my-1 leading-4">{product.count} </p>
                                            </div>
                                            <h6 className="font-medium text-lg leading-8 text-indigo-600  max-[550px]:text-center">Цена: {+product.cost * +product.count} ₽</h6>
                                        </div>
                                    </div>
                                </div>
                            })
                            }
                        </div>
                    })
                }

                {
                    orders.length === 0 &&
                    <div className="w-full h-full flex items-center justify-center">
                        <h1 className="md:text-4xl text-2xl text-center italic pb-20">Вы ничего не заказали...</h1>
                    </div>
                }
            </div>
        </>

    );
};

export default Orders;
