import { useState } from 'react';
const Dostavka = () => {
    const [target, setTarget] = useState('')
    return (
        <>
            <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.1') setTarget('1.1'); else { setTarget('') } }}>Способы доставки</h4>
            <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.1' ? `max-h-[1500px] p-4` : ''}`}>
                <p>1. Самовывоз</p>
                <p>2. Почта России</p>
                <p>3. В пунктах выдачи СДЭК </p>
                <h1 className='pt-[10px]'><strong>В случае самовывоза</strong></h1>
                <p>Магазин poGHOSTy</p>
                <p>г. Оренбург, ул. Салмышская, д. 41</p>
                <p>тел. +7(999)968-23-54</p>
            </div>
            <div>
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.2') setTarget('1.2'); else { setTarget('') } }}>Сроки доставки</h4>
                <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.2' ? `max-h-[1500px] p-4` : ''}`}>
                    <p>Курьерская доставка по г. Оренбург от 1 до 2 дней;</p>
                    <p> Доставка почтой по России от 7 до 16 дней;</p>
                </div>
            </div>
            <div >
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.3') setTarget('1.3'); else { setTarget('') } }}>Стоимость доставки</h4>
                <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.3' ? `max-h-[1500px] p-4` : ''}`}>
                    <p>Курьером от 320 руб.</p>
                    <p>Почтой от 590 руб.</p>
                    <p>Стоимость варьируется в зависимости от удаленности от г. Оренбург</p>
                </div>
            </div>
        </>
    );
};

export default Dostavka;
