import { useState } from 'react';
const Vozvrat = () => {
    const [target, setTarget] = useState('')
    return (
        <>
            <div>
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '3.1') setTarget('3.1'); else { setTarget('') } }} >Как я могу вернуть или заменить купленный товар?</h4>
                <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '3.1' ? `max-h-[1500px] p-4` : ''}`}>

                    <p>Вы можете вернуть изделие до его передачи в службу доставки или в случае выявленного брака – в течение 7 дней. Важное требование: сохранение всех бирок и отсутствие следов использования. Стоимость доставки качественного товара не возвращается. </p>
                </div>
            </div>
            <div>
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '3.2') setTarget('3.2'); else { setTarget('') } }}>Сроки возврата</h4>
                <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '3.2' ? `max-h-[1500px] p-4` : ''}`}>
                    <p>После согласования решения о возврате средств, деньги будут возвращены в течение 2-10 рабочих дней.</p>
                </div>
            </div>
        </>
    );
};

export default Vozvrat;
