import { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Dostavka from './Help/Dostavka';
import Oplata from './Help/Oplata';
import Vozvrat from './Help/Vozvrat';
import Bezopasnost from './Help/Bezopasnost';
import Contacts from './Help/Contacts';
import { useParams } from "react-router";

const Help = () => {
    const [target, setTarget] = useState('')
    const { option } = useParams()
    const [isOption, setIsOption] = useState(option)
    const location = useLocation();
    const navigate = useNavigate();
    const handleGoBack = () => {
        if (location.state && location.state.prevPath) {
            navigate(location.state.prevPath, { replace: true });
        } else {
            navigate(-1);
        }
    };
    const handleNavigateHelp = (option) => {
        setIsOption(`${option}`)
        navigate(`/help/${option}`)
    }

    return (
        <>
            <Link to='#' className="border border-white text-black bg-white p-2 rounded-md text-nowrap inline-flex items-center mb-4" onClick={() => handleGoBack()}><MdOutlineKeyboardBackspace className="text-[20px] text-black" /><b>Назад</b></Link>
            <div className="text-black w-[100%] min-h-[100vh] p-8 rounded-md bg-white">
                <h1 className="text-center text-[36px] mb-6">Помощь</h1>

                <div className='mb-1 text-[24px]'>
                    <h3><b>Внимание!</b></h3>
                </div>
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '0.0') setTarget('0.0'); else { setTarget('') } }}>Спасибо за внимание :)</h4>
                <div className={`mb-2 rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 max-h-[1500px] p-4 `}>
                    Не все позиции, представленные на сайте, есть в наличии. Наш менеджер сообщит Вам об этом. В данном случае, время ожидания заказа может продлиться до 5-7 дней, что является разумной ценой за высокое качество наших российских мастеров (я против пошива гигантских партий в Китае).
                </div>
                <div className='mb-4 flex-col flex md:flex-row md:justify-start justify-center items-center'>
                    <button className={`rounded-[20px] w-full mb-2 border border-black p-2 ${isOption === "dostavka" ? 'bg-[#1d1d1b] text-white' : ''}`} onClick={e => handleNavigateHelp('dostavka')}>Доставка</button>
                    <button className={`rounded-[20px] w-full mb-2 border border-black p-2 md:ml-4 ${isOption === "oplata" ? 'bg-[#1d1d1b] text-white' : ''}`} onClick={e => handleNavigateHelp('oplata')}>Оплата</button>
                    <button className={`rounded-[20px] w-full mb-2 border border-black p-2 md:ml-4 ${isOption === "vozvrat" ? 'bg-[#1d1d1b] text-white' : ''}`} onClick={e => handleNavigateHelp('vozvrat')}>Возврат</button>
                    <button className={`rounded-[20px] w-full mb-2 border border-black p-2 md:ml-4 ${isOption === "bezopasnost" ? 'bg-[#1d1d1b] text-white' : ''}`} onClick={e => handleNavigateHelp('bezopasnost')}>Безопасность</button>
                    <button className={`rounded-[20px] w-full mb-2 border border-black p-2 md:ml-4 ${isOption === "contacts" ? 'bg-[#1d1d1b] text-white' : ''}`} onClick={e => handleNavigateHelp('contacts')}>Контакты</button>
                </div>
                {isOption === 'dostavka' ? <Dostavka /> : isOption === 'oplata' ? <Oplata /> : isOption === 'vozvrat' ? <Vozvrat /> : isOption === 'bezopasnost' ? <Bezopasnost /> : isOption === 'contacts' ? <Contacts /> : ''}
            </div >
        </>

    );
};

export default Help;
