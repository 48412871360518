
import { motion } from 'framer-motion';
// import Poghosty from './Poghosty'
import { useInView } from 'react-intersection-observer';
const Section = () => {
    const arrText = 'Я предлагаю иной взгляд на устоявшиеся нормы стритвира. Делаю не то, что нужно, а то, что хочу.'.split(' ')
    const { ref, inView } = useInView({
        triggerOnce: true, // Анимация будет проигрываться только один раз
        threshold: 0, // Порог видимости (10%)
    });
    const container = {
        hidden: { opacity: 0 },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
        })
    }
    const child = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring',
                damping: 12,
                stiffnes: 100,
            }
        },
        hidden: {
            opacity: 0,
            y: 20,
            transition: {
                type: 'spring',
                damping: 12,
                stiffnes: 100
            }
        }
    }

    return (
        <>
            <div className='mb-4'>
                <div className='flex relative'>
                    <div className='md:flex hidden w-[50%] items-center justify-center relative'>
                        {/* <Poghosty /> */}
                        <motion.div
                            initial={{ x: -1000, y: -200, opacity: 0 }}
                            animate={{ x: 0, y: 0, opacity: 1 }}
                            transition={{
                                duration: 0.8
                            }}
                            className='main-text text-center lg:text-[36px] lg:leading-[36px] xl:text-[52px] xl:leading-[52px] 2xl:text-[66px] 2xl:leading-[66px] text-[28px] font-[700] leading-[28px] p-8 md:block hidden'
                        >
                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='text-nowrap'>Все в моей стране</span><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='text-nowrap'>Делается поГОСТу</span><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;<span className='text-nowrap'>Я же делаю</span><br />
                            &nbsp;&nbsp;<span className='text-nowrap'>По GHOSTy</span><br /> */}
                            Все в моей стране<br />
                            Делается поГОСТу<br />
                            Я же делаю<br />
                            По GHOSTy<br />

                        </motion.div>
                    </div>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 6
                        }}
                        className='md:w-[50%] w-full'
                    >
                        <img src={`${process.env.PUBLIC_URL}img/resources/sectionImg_1.jpg`} alt='model' className='rounded-[20px]' />
                        <div className='md:w-[50%] flex-col md:p-8 p-0 items-center justify-center w-full absolute top-0 h-full rounded-[20px] bg-[#1e1e1c]/20 left-0 md:hidden flex '>
                            <div className='main-text bg-black/30 p-2 w-full justify-center flex mt-[20px] backdrop-blur-[4px] mb-[2px]'>
                                <h1>poGHOSTy.</h1>
                            </div>
                            <span className='main-text sm:text-[36px] sm:leading-[36px] text-[28px] text-center font-[700] leading-[28px] p-8'>Я предлагаю иной взгляд на устоявшиеся нормы стритвира. Делаю не то, что нужно, а то, что хочу.</span>
                        </div>
                    </motion.div>
                </div>
                <div className='flex justify-center items-center'>
                    <div className='md:w-[50%] md:block hidden w-full'>
                        <img src={`${process.env.PUBLIC_URL}/img/resources/sectionImg_2.jpg`} alt='model' className='rounded-[20px]' />
                    </div>
                    <motion.div
                        variants={container} initial={!inView ? `hidden` : ''} animate={inView ? `visible` : ''}
                        className='hidden md:block md:w-[50%] md:p-8 p-0 text-wrap items-center lg:text-[36px] lg:leading-[36px] xl:text-[52px] xl:leading-[52px] 2xl:text-[66px] 2xl:leading-[66px] text-[28px] leading-[28px]'>
                        {
                            arrText.map((word, i) => {

                                return (
                                    <motion.span
                                        ref={ref}
                                        variants={child}
                                        key={i} className={`main-text font-[700] pr-6`}>
                                        {i === 0 ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0` : i === 4 ? `\u00A0\u00A0\u00A0` : i === 6 ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0` : i === 11 ? `\u00A0\u00A0\u00A0` : i === 13 ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0` : ""}{word}{i === 2 || i === 3 || i === 5 || i === 7 || i === 10 || i === 12 ? <br /> : ''}
                                    </motion.span>
                                );
                            })
                        }
                    </motion.div>
                </div>
            </div >
        </>
    );
};

export default Section;