import { useState } from "react";
import axios from 'axios';
import { Context } from "../../App";
import { useContext } from "react";
const Authorization = (props) => {
    const { getDate } = props.props
    const { setUser, setCart, setFavorites } = useContext(Context)
    // Ввод пользователя
    const [authorization, setAuthorization] = useState({
        login: '',
        password: ''
    })
    const [errorValidation, setErrorValidation] = useState({
        loginError: false,
        passwordError: false,
    })
    // Виден ли пароль
    const [isViewPassword, setIsViewPassword] = useState(false)

    const handleUserFormChanger = (e, loginPassword) => {
        setAuthorization({ ...authorization, [loginPassword]: e.target.value })
    }
    // Отправка входа
    const handleSendAuthorization = (event) => {
        event.preventDefault();
        const cart = JSON.parse(localStorage.getItem('cart'))
        const favorites = JSON.parse(localStorage.getItem('favorites'))
        const now = getDate()
        axios.post(`https://poghosty.ru/api/poghosty-authorization.php`, { login: authorization.login, password: authorization.password, cart: cart, favorites: favorites, lastDateVisit: now.formattedDate, lastTimeVisit: now.formattedTime })
            .then(response => {
                if (response.data['error'] ? true : false) {
                    const newError = { ...errorValidation, [response.data['type']]: response.data['error'] }
                    setErrorValidation(newError)
                } else {
                    localStorage.setItem('user', JSON.stringify(response.data['user']))
                    setUser(response.data['user'])
                    /////////////////////////////////////////////////////////////////////
                    localStorage.setItem('favorites', response.data['user']['favorites'])
                    setFavorites(JSON.parse(response.data['user']['favorites']))
                    /////////////////////////////////////////////////////////////////////
                    localStorage.setItem('cart', response.data['user']['cart'])
                    setCart(JSON.parse(response.data['user']['cart']))
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    // // Выход из аккаунта
    // const logout = () => {
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('cart');
    //     localStorage.removeItem('favorites');
    //     localStorage.removeItem('cartCounter');
    //     localStorage.removeItem('favoritesCounter');
    //     onSetCartCounter(0)
    //     onSetFavoritesCounter(0)
    //     setUser({})
    //     setIsAuthenticated(false)
    // }

    // Скрытие пароля 

    return (
        <div className="w-[100%] h-[100%] bg-[#1e1e1c] p-2">
            <form action="" className="flex flex-col items-center relative">
                <label className="text-white w-[50%]" htmlFor={'login'} > Логин:</label>
                <input className={`w-[50%] border-none outline-none pl-1 ${errorValidation.loginError ? `shadow-[inset_0_0_2px_3px_rgba(201,21,21,0.4)]` : ''}`} value={authorization.login} type="text" name={'login'} onChange={(e) => { handleUserFormChanger(e, 'login'); setErrorValidation({ ...errorValidation, loginError: false }) }}></input>
                {errorValidation.loginError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">{errorValidation.loginError}</p>}
                <label className="text-white w-[50%]" htmlFor="password"> Пароль:</label>
                <div className="w-1/2 relative">
                    <input className={`w-[100%] border-none outline-none pl-1 ${errorValidation.passwordError ? `shadow-[inset_0_0_2px_3px_rgba(201,21,21,0.4)]` : ''}`} value={authorization.password} type={`${isViewPassword ? "text" : "password"}`} name="password" onChange={(e) => { handleUserFormChanger(e, 'password'); setErrorValidation({ ...errorValidation, passwordError: false }) }} />
                    {errorValidation.passwordError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">{errorValidation.passwordError}</p>}
                    <button
                        className="absolute h-[20px] top-[4px] right-[-10px] inset-y-0 flex items-center px-4 text-gray-600"
                        onClick={(e) => { e.preventDefault(); setIsViewPassword(!isViewPassword) }}
                    >
                        {isViewPassword ? <svg
                            xmlns="https://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg> :

                            <svg
                                xmlns="https://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                            </svg>
                        }</button>
                </div>
                <button className="text-white border-2 rounded-[20px] px-3 py-2 my-4 w-[50%]" onClick={(e) => { e.preventDefault(); handleSendAuthorization(e); }}>Войти</button>
            </form>
        </div>
    );
};

export default Authorization;
