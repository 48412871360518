import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import FindProducts from "./FindProducts";
import Mans from "./Mans";
import Womans from "./Womans";
import UserButtons from "./UserButtons";
import User from "./User";
import Authorization from "./Authorization";
import Registration from "./Registration";
import { BsCart2 } from "react-icons/bs";
import { GrFavorite } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { Context } from "../../App";
import { motion, useScroll, useSpring } from "framer-motion";

const HeaderApp = (props) => {
    const { products, searchValue, setSearchValue, setSortName, setFilterValues, setFilterGender, setPage, getDate } = props.props
    const { user, setUser, cart, setCart, favorites, setFavorites } = useContext(Context);
    // Движение хедера
    const [headerMove, setHeaderMove] = useState(false)

    const [openBurger, setOpenBurger] = useState(false)

    const [openMyProfile, setOpenMyProfile] = useState(false)

    const [clickRegistrationOrAuthorization, setClickRegistrationOrAuthorization] = useState(true)

    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });
    const handleClickRegistrationOrAuthorization = () => {
        setClickRegistrationOrAuthorization(!clickRegistrationOrAuthorization)
    }
    // 1-ый и 2-й уровень
    const womans = products && products.filter(product => product.gender === 'w')
    const mans = products && products.filter(product => product.gender === 'm')

    useEffect(() => {
        const handleChangeHeader = () => {
            if (window.scrollY >= 15) {
                setHeaderMove(true)
            } else {
                setHeaderMove(false)
            }
        }
        window.addEventListener('scroll', handleChangeHeader)
        return () => {
            window.removeEventListener('scroll', handleChangeHeader);
        };
    }, [headerMove])

    // Скролл в начало при нажатии на страницу
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    // Поиск
    const handleSearchValue = (e) => {
        setSearchValue(e.target.value)
    }
    const handleOpenMyProfile = () => {
        setOpenMyProfile(!openMyProfile)
    }
    const handleClickLogotype = () => {
        setFilterValues({});
        setFilterGender('');
        setSortName('');
        localStorage.setItem('page', 1);
        setPage(1);
        handleScrollToTop()
    }
    const propsFindProducts = {
        products: products,
        searchValue: searchValue,
    }
    const propsMans = {
        mans: mans,
        headerMove: headerMove,
        setFilterValues: setFilterValues,
        setFilterGender: setFilterGender,
        setPage: setPage,
        handleScrollToTop: handleScrollToTop
    }
    const propsWomans = {
        womans: womans,
        headerMove: headerMove,
        setFilterValues: setFilterValues,
        setFilterGender: setFilterGender,
        setPage: setPage,
        handleScrollToTop: handleScrollToTop
    }
    const propsUserButtons = {
        handleScrollToTop: handleScrollToTop,
        headerMove: headerMove,
        openBurger: openBurger,
        cart: cart,
        favorites: favorites,
        setOpenBurger: setOpenBurger,
        openMyProfile: openMyProfile,
        handleOpenMyProfile: handleOpenMyProfile,
        setOpenMyProfile: setOpenMyProfile,
    }
    const propsAuthorization = {
        getDate: getDate
    }
    const propsRegistration = {
        setUser: setUser,
        cart: cart,
        setCart: setCart,
        favorites: favorites,
        setFavorites: setFavorites,
        getDate: getDate
    }
    const propsUser = {
        openMyProfile: openMyProfile,
        setOpenMyProfile: setOpenMyProfile,
    }
    return (
        <header className={`relative flex justify-between border-b rounded-b-[10px] px-4 mb-4 border-gray-300 h-[82px] ${headerMove ? `text-black sticky top-0 z-[9999]  mb-0 pt-[16px] h-[98px] bg-white/80` : ''}`}>
            <motion.div className="fixed top-0 left-0 right-0 h-[6px] bg-black origin-[0%]" style={{ scaleX }} />

            {openMyProfile && <div className='fixed h-full w-full bg-transparent top-0 left-0 z-[9999]' onClick={e => { if (e.target === e.currentTarget) { handleOpenMyProfile() } }}></div>}
            <div className='flex justify-between items-center pb-3'>
                <Link to={'/'} onClick={handleClickLogotype}>
                    <img src={headerMove ? `${process.env.PUBLIC_URL}/img/resources/logotype_black.png` : `${process.env.PUBLIC_URL}/img/resources/logotype_white.png`} alt='logotype' className='cursor-pointer lg:max-w-[200px] max-w-[150px]' />
                </Link>
            </div>
            <div className={`w-full flex flex-col ${headerMove ? `text-black border-black ` : ''}`}>
                <div className='flex justify-center relative'>
                    {searchValue && <div className="w-[100%] fixed left-0 top-[96px] h-[100%] bg-transparent z-[9998]" onClick={e => { if (e.target === e.currentTarget) { setSearchValue('') } }}></div>}
                    <input type='text' value={searchValue} onChange={e => handleSearchValue(e)} className={`${headerMove ? ` border-2 border-black ` : ''}  outline-none lg:w-[60%] sm:w-[80%] sm:block hidden p-1 text-gray-900 `} placeholder='Поиск по магазину...' />
                    {searchValue && <div className="lg:w-[60%] sm:w-[80%] sm:block hidden bg-white absolute top-[44px] rounded-[4px] left-25 text-black z-[9999] pt-4 pr-4 pl-4 pb-2  shadow-md overflow-y-auto">
                        <FindProducts props={propsFindProducts} />
                    </div>}
                </div>
                <div className='gender flex justify-center  h-[100%]'>
                    <ul className='flex lg:w-[60%] w-[80%] justify-around z-[9994]'>
                        <Mans props={propsMans} />
                        <Womans props={propsWomans} />
                    </ul>
                </div>
            </div>
            <UserButtons props={propsUserButtons} />
            <div className={`text-black ${openBurger ? 'p-4' : ''} flex flex-col right-0 sm:w-1/3 xl:w-1/4 shadow-xl shadow-black w-[100%] absolute overflow-hidden top-0 max-h-[0px] transition-all ease-in-out duration-500 rounded-md ${openBurger ? 'max-h-[400px]' : ''} bg-white z-[9999]`}>
                <div className=' w-full flex justify-end'>
                    <button className=' text-[32px]' onClick={() => setOpenBurger(!openBurger)}><IoClose /></button>
                </div>
                <input type='text' value={searchValue} onChange={e => handleSearchValue(e)} className={`border-2 sm:hidden block border-black  outline-none p-1 text-gray-900 `} placeholder='Поиск по магазину...' />
                {searchValue && <div className=" bg-white min-w-[250px] pl-2 sm:hidden block rounded-[4px] text-black z-[9999] shadow-md overflow-y-auto">
                    <FindProducts props={propsFindProducts} />
                </div>}
                <Link to={'/cart'} className='flex items-center leading-10 pt-2'>
                    <BsCart2 className='mb-1 mr-2' />
                    <p className='underline-offset-4 underline'>Корзина</p>
                </Link>
                <Link to={'/favorites'} className='flex items-center leading-10'>
                    <GrFavorite className='mb-1 mr-2' />
                    <p className='underline-offset-4 underline'>Избранное</p>
                </Link>
            </div>
            {openMyProfile &&
                <div className="absolute top-[98px] right-0  h-[100%] bg-transparent z-[9999]">
                    <div className={`shadow-sm z-[9999] w-[300px] bg-[white] shadow-white border border-white rounded-[20px] text-black overflow-hidden`}>
                        {
                            user &&
                            <User props={propsUser} />
                        }
                        {!user &&
                            <div className="flex">
                                <button className={`${clickRegistrationOrAuthorization ? `bg-[#1e1e1c] text-white` : `bg-white text-black`}  w-[100%] h-[50px] text-center py-2`} onClick={handleClickRegistrationOrAuthorization}>Регистрация</button>
                                <button className={`${!clickRegistrationOrAuthorization ? `bg-[#1e1e1c] text-white` : `bg-white text-black`} w-[100%] h-[50px] text-center py-2`} onClick={handleClickRegistrationOrAuthorization}>Вход</button>
                            </div>}
                        {!user && clickRegistrationOrAuthorization &&
                            <Registration props={propsRegistration} />
                        }
                        {!user && !clickRegistrationOrAuthorization &&
                            <Authorization props={propsAuthorization} />
                        }
                    </div>
                </div>
            }
        </header >
    );
};

export default HeaderApp;
